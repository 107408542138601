import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import Login from "./Login";
import Data from "./Data";

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/" element={<App />}></Route>
                <Route path="/dash" element={<Data/>}></Route>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
