import {Button, Form, Input, PasscodeInput, Toast} from "antd-mobile";
import React from "react";

const LoginPage = () => {
    const [form] = Form.useForm();
    const onFinish = () => {
        let fieldsValue = form.getFieldsValue();
        fetch('/report/login', {method: 'POST',
            headers:{'Content-Type':'application/json'},
            body: JSON.stringify(fieldsValue)}).then(async (res) => {
            const {data} = await res.json();
            if (!data) {
                Toast.show("登录失败")
            } else {
                window.localStorage.setItem("userId", data)
                window.location.href = "/"
            }
        })
    }
    return <div>
        <Form
            form={form}
            onFinish={onFinish}
            footer={
                <Button block type='submit' color='primary' size='large'>
                    提交
                </Button>
            }
        >
            <Form.Header>登录</Form.Header>
            <Form.Item name='username' label='用户名' rules={[{required: true}]}>
                <Input placeholder='请输入用户名'/>
            </Form.Item>
            <Form.Item name='password' label='密码' rules={[{required: true}]}>
                <Input type='password' placeholder='请输入密码'/>
            </Form.Item>
        </Form>
    </div>
}
export default LoginPage