import React, {useEffect, useState} from 'react';
import './App.css';
import { Grid, Toast} from "antd-mobile";

function Data() {
    const [itemData,setItemData] = useState<any[]>([])
    const queryData = () => {
        fetch(`/report/datas`).then(async (res) => {
            let {data} = await res.json();
            const tempData = [];
            if (!data) {
                Toast.show("暂无数据")
                return;
            }
            tempData.push({label:'大公初检',value:data.count})
            if(data?.datas){
                data?.datas.map((e:any)=>{
                    tempData.push({label:e.nickname,value:e.totalCount})
                })
            }
            console.log(tempData)
            setItemData(tempData)
        })
    }
    useEffect(()=>{
        queryData()
    },[])
    return (
        <div className="App">
            <Grid columns={3} gap={8}>
                {
                    itemData?.map((item:any)=>
                        <Grid.Item style={{borderRadius:'10px',fontSize:'20px',border:'1px solid #ddd'}}>
                            <p>{item.label}</p>
                            <p style={{color:'red'}}>{item.value}</p>
                            </Grid.Item>
                    )
                }
            </Grid>
            {/*{*/}
            {/*    data?.datas?.map((item:any)=><div>*/}
            {/*        {item.nickname}:{item.totalCount}*/}
            {/*    </div>)*/}
            {/*}*/}
        </div>
    );
}

export default Data