import React, {useEffect, useState} from 'react';
import './App.css';
import {Button, DatePicker, Form, Input, Toast} from "antd-mobile";
import dayjs from "dayjs";

function App(props:any) {
    const [report, setReport] = useState<any>();
    const [userId, setUserId] = useState<number>();
    const [vin, setVin] = useState<string>();
    const [form] = Form.useForm()
    const [pickVisible,setPickVisible] = useState<boolean>(false)
    const [outPickerVisible,setOutPickerVisible] = useState<boolean>(false)
    const queryData = () => {
        fetch(`/report/vin/${userId}/${vin}`).then(async (res) => {
            let {data} = await res.json();
            if(!data){
                Toast.show("查无此报告")
                return;
            }
            debugger
            if(data.licenseTime && data.licenseTime!='暂无') {
                data.licenseTime = dayjs(data.licenseTime).toDate()
            }else{
                data.licenseTime = dayjs().toDate()
            }
            if(data.outTime&& data.outTime!='暂无') {
                data.outTime = dayjs(data.outTime).toDate()
            }else{
                data.outTime = dayjs().toDate()
            }
            setReport(data)
            form?.setFieldsValue(data)
        })
    }
    const onFinish = () => {
        let fieldsValue = form.getFieldsValue();
        fetch(`/report/vin/${vin}`,{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body: JSON.stringify({...report,...fieldsValue,userId,
                licenseTime:dayjs(fieldsValue.licenseTime).format("YYYY-MM"),
                outTime:dayjs(fieldsValue.outTime).format("YYYY-MM"),
            })}
        ).then(async (res) => {
            let {data} = await res.json();
            if(data){
                Toast.show("保存成功")
                form.setFieldsValue({})
            }else{
                Toast.show("保存失败")
            }
        })
    }
    // useEffect(() => {
    //     // setVin('')
    // }, [])
    useEffect(()=>{
        let item:any = window.localStorage.getItem("userId");
        console.log(props)
        if(!item){
            window.location.href="/login"
            return;
        }
        setUserId(item);
        if(vin){
            queryData()
        }
    },[vin])
    return (
        <div className="App">
            <Form
                form={form}
                onFinish={onFinish}
                footer={
                    <Button block type='submit' color='primary' size='large'>
                        提交
                    </Button>
                }
            >
                <Form.Header>车辆信息采集</Form.Header>
                <Form.Item name='vin' label='车架号' rules={[{required: true}]}>
                    <Input placeholder='请输入车架号'/>
                </Form.Item>
                <button type="button" onClick={()=>{
                    const vin = form?.getFieldValue("vin")
                    console.log(form.getFieldsValue())
                    setVin(vin)
                }}>查询</button>
                <Form.Item name='name' label='车型名称' rules={[{required: true}]}>
                    <Input placeholder='请输入name'/>
                </Form.Item>
                <Form.Item name='interiorColor' label='车辆颜色' rules={[{required: true}]}>
                    <Input placeholder='请输入车辆颜色'/>
                </Form.Item>
                <Form.Item name='carColor' label='内饰颜色' rules={[{required: true}]}>
                    <Input placeholder='请输入内饰颜色'/>
                </Form.Item>
                <Form.Item name='secondPrice' label='车价(元)' rules={[{required: true}]}>
                    <Input placeholder='请输入车价'/>
                </Form.Item>
                <Form.Item name='licensePlace' label='上牌地点'>
                    <Input placeholder='请输入上牌地点'/>
                </Form.Item>
                <Form.Item name='displayedMileage' label='公里数' rules={[{required: true}]}>
                    <Input placeholder='请输入公里数'/>
                </Form.Item>
                <Form.Item name='licenseTime' label='上牌日期'
                           trigger='onConfirm'
                           onClick={()=>{setPickVisible(true)}} rules={[{required: true}]}>
                    <DatePicker
                        visible={pickVisible}
                        precision="month"
                        min={new Date(2012,0,0)}
                        onClose={() => {
                            setPickVisible(false)
                        }}
                    >
                        { value=>value?dayjs(value).format('YYYY-MM') : '请选择日期'
                        }
                    </DatePicker>
                </Form.Item>
                <Form.Item name='outTime' label='出厂年份'
                           onClick={()=>{setPickVisible(true)}}
                           trigger='onConfirm' rules={[{required: true}]}>
                    <DatePicker
                        visible={outPickerVisible}
                        precision="month"
                        min={new Date(2012,0,0)}
                        onClose={() => {
                            setOutPickerVisible(false)
                        }}
                    >
                        {value =>
                            value ? dayjs(value).format('YYYY-MM') : '请选择日期'
                        }
                    </DatePicker>
                </Form.Item>
                <Form.Item name='phone' label='车商手机号' help='车商手机号' rules={[{required: true}]}>
                    <Input placeholder='请输入车商手机号'/>
                </Form.Item>
                <Form.Item name='newCarPrice' label='新车价格(元)' rules={[{required: true}]}>
                    <Input placeholder='请输入新车价格'/>
                </Form.Item>
                <Form.Item name='discharge' label='环保等级' help='环保等级'>
                    <Input placeholder='请输入环保等级'/>
                </Form.Item>
                <Form.Item name='displacement' label='排放' help='排放'>
                    <Input placeholder='请输入排放'/>
                </Form.Item>
            </Form>
        </div>
    );
}

export default App;
